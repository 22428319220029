@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("../fonts/calibri_bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
