@import url(./reset.scss);
@import url(./fonts.scss);

body {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  line-height: 1.4;
  font-size: 1.125rem;
  color: #585858;

  .content {
    max-width: 960px;
    margin: 0 auto;
    padding: 16px;
  }

  a {
    color: inherit;
  }

  b {
    font-weight: bold;
  }

  .red {
    color: #d71a1a;
  }

  p + p {
    margin-top: 8px;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }

  h2 {
    color: #d71a1a;
    font-size: 1.25rem;
    margin-bottom: 8px;
  }

  h1 {
    color: #d71a1a;
    font-size: 1.5rem;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.separator {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    height: 1px;
    background: #ccc;
    left: 0;
    right: 0;

    transform: translateY(-50%);
  }
}

.marker-list {
  list-style: inside disc;
  padding-left: 16px;
}

header {
  flex: 0 0;

  text-align: center;

  h1 {
    margin-bottom: 0;
  }
}

.navigation {
  flex: 0 0;
  text-align: center;

  .navigation-content {
    position: relative;
    z-index: 2;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    background: #fff;

    font-weight: 1rem;

    .item {
      padding: 4px;
      text-transform: uppercase;

      a {
        text-decoration: none;

        display: inline-block;
        padding: 8px 12px;
        border-radius: 2px;

        &.active {
          background: #d71a1a;
          color: #f0f0f0;
        }

        &:hover:not(.active) {
          background: #f8f8f8;
        }
      }
    }
  }
}

main {
  flex-shrink: 0;
  flex-grow: 1;

  padding-top: 24px;
  padding-bottom: 24px;

  .about {
    display: grid;
    grid-template-columns: 33% minmax(200px, auto);
    grid-gap: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .imprint {
    h3 {
      font-size: 1.25rem;
      font-weight: bold;
    }

    p + h3 {
      margin-top: 8px;
    }
  }

  .contacts {
    a:hover {
      color: #d71a1a;
    }
  }

  .services-list {
    a {
      display: inline-block;
      background: #f8f8f8;
      text-decoration: none;

      padding: 8px 12px;
      border-radius: 2px;
      &:hover {
        background: #d71a1a;
        color: #f0f0f0;
      }
    }
  }

  .services-view {
    h3 {
      font-weight: bold;
      margin-bottom: 12px;
    }
  }

  @media only screen and (max-width: 420px) {
    .about {
      grid-template-columns: 1fr;
    }
  }
}

footer {
  flex: 0 0;
  font-size: 1rem;

  .footer-content {
    padding-top: 24px;
    padding-bottom: 16px;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    p {
      margin-bottom: 8px;
    }

    a {
      color: #d71a1a;
    }
  }
}
